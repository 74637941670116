import { StatusBar } from "expo-status-bar";
import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { FC, Suspense } from "react";

import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";
import * as firebase from "firebase";

import { useAuthSetup, UserContext } from "./hooks/auth";
import { Button, Text, View } from "react-native";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { Loading } from "./components/Loading";

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      suspense: true,
    },
  },
});

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  if (!isLoadingComplete) {
    return null;
  }

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <RecoilRoot>
        <Suspense fallback={<Loading />}>
          <SafeAreaProvider>
            <Navigation colorScheme={colorScheme} />
            <StatusBar />
          </SafeAreaProvider>
        </Suspense>
      </RecoilRoot>
    </ReactQueryCacheProvider>
  );
}
