import * as React from "react";
import { FC } from "react";
import { StyleSheet, Text } from "react-native";
import { View } from "../Themed";
import { Talk } from "../../services/api";

type TalkProps = {
  talk: Talk;
};

export const TalkView: FC<TalkProps> = ({ talk }) => {
  return (
    <View>
      <View>
        <Text style={styles.talkName}>
          {talk.description} ({talk.minutes} 分)
        </Text>
      </View>

      <View>
        <Text>金額: {talk.price} 円</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  talkName: {
    fontSize: 20,
    fontWeight: "bold",
    marginVertical: 8,
  },
});
