import * as React from "react";
import { FC } from "react";
import { StyleSheet } from "react-native";
import { View } from "../Themed";
import { Read } from "../../services/api";
import { Description } from "../Description";
import { Input } from "react-native-elements";

type TalkProps = {
  read: Read;
};

export const ReadView: FC<TalkProps> = ({ read }) => {
  const prices = read.prices;

  return (
    <>
      <Input
        placeholder="https://example.com/"
        label="読んでほしいページの URL"
        labelStyle={[styles.label]}
        containerStyle={styles.input}
        leftIcon={{ type: "material-community", name: "web" }}
      />

      <View style={styles.descriptionBox}>
        <Description>
          読み上げた時間に応じて、以下の費用がかかります。
        </Description>
        <View style={styles.prices}>
          {prices.map((a) => (
            <View key={a.minutes} style={styles.price}>
              <Description>
                {a.minutes} 分: {a.price} 円
              </Description>
            </View>
          ))}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  label: {},
  descriptionBox: {
    marginVertical: 4,
  },
  prices: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  price: {
    flexDirection: "row",
    marginRight: 4,
  },
  input: {
    marginTop: 16,
    marginLeft: -10,
  },
});
