import * as React from "react";
import { FC, useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "../Themed";
import { CastAvatar } from "../CastAvatar";
import { CastNameAndSampleVoice } from "../CastNameAndSampleVoice";
import { TalkView } from "./TalkView";
import { Button, Input } from "react-native-elements";
import { primaryColor } from "../../constants/Colors";
import { ReadView } from "./ReadView";
import { Description } from "../Description";
import { SingView } from "./SingView";
import { logShowContentDetail } from "../../utils/analytics";
import { Content } from "../../types";

export type ContentDetailProps = {
  content: Content;
};

export const ContentDetail: FC<ContentDetailProps> = ({ content }) => {
  const [name, setName] = useState("");
  const cast = content.station.cast;

  logShowContentDetail(content);

  return (
    <View style={styles.container}>
      <View style={styles.cast}>
        <CastNameAndSampleVoice cast={cast} />
        <View style={styles.avatar}>
          <CastAvatar cast={cast} />
        </View>
      </View>

      {content.type === "talk" && <TalkView talk={content.talk} />}
      {content.type === "read" && <ReadView read={content.read} />}
      {content.type === "sing" && <SingView sing={content.sing} />}

      <Input
        placeholder="たろう"
        label="あなたのお名前 (配信者に通知されます)"
        value={name}
        onChangeText={setName}
        containerStyle={[styles.input]}
        leftIcon={{ type: "material-community", name: "account-edit" }}
      />

      <View style={styles.buttonWrap}>
        <Button
          title="ユーザー登録へ進む"
          disabled={!name}
          buttonStyle={[styles.primaryButton]}
          raised
          onPress={() => {
            alert("開発中");
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    padding: 8,
  },
  avatar: {
    marginRight: 4,
  },
  cast: {
    marginBottom: 12,
    flexDirection: "column",
    alignItems: "center",
  },
  descriptionBox: {
    marginVertical: 16,
  },
  input: {
    marginTop: 16,
    marginLeft: -10,
    // ラベルが折り返さない程度
    minWidth: 320,
  },
  buttonWrap: {
    marginVertical: 4,
  },
  primaryButton: {
    backgroundColor: primaryColor,
  },
});
