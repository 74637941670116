import * as React from "react";
import { FC } from "react";
import { StyleSheet } from "react-native";
import { View } from "../../components/Themed";
import { MainText } from "../../components/MainText";
import { Header } from "../../components/Header";

export type RequestScreenTemplateProps = {};

export const RequestScreenTemplate: FC<RequestScreenTemplateProps> = ({}) => {
  return (
    <View>
      <Header />
      <View style={styles.text}>
        <MainText padding>
          Your Radio に興味を持っていただきありがとうございます。
        </MainText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    flexDirection: "column",
    alignItems: "center",
  },
});
