import * as React from "react";
import { FC, useEffect } from "react";
import { RootStackParamList } from "../../types";
import { StackScreenProps } from "@react-navigation/stack";
import { RequestScreenTemplate } from "./RequestScreenTemplate";
import { logCurrentScreen, logShowRequestScreen } from "../../utils/analytics";

type Props = StackScreenProps<RootStackParamList, "Request">;

/**
 * アンケートのお願い
 *
 * 遷移元によってことなる
 */
export const RequestScreen: FC<Props> = ({ route }) => {
  const from = route.params.from;

  useEffect(() => {
    logCurrentScreen("request");
    logShowRequestScreen(from);
  }, []);

  return <RequestScreenTemplate />;
};
