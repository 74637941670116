import * as React from "react";
import { FC } from "react";
import { StyleSheet } from "react-native";
import { Text, View } from "../../components/Themed";
import { Language } from "../../services/api";
import { primaryColor2 } from "../../constants/Colors";

export type StationViewProps = {
  language: Language;
};

export const ReadView: FC<StationViewProps> = ({ language }) => {
  return (
    <View style={styles.read}>
      <Text>{language} OK</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  read: {
    borderRadius: 12,
    paddingHorizontal: 8,
    height: 32,
    alignItems: "center",
    flexDirection: "row",
    margin: 4,
    backgroundColor: primaryColor2,
    overflow: "hidden",
  },
});
