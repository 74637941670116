import * as Analytics from "expo-firebase-analytics";
import { Content } from "../types";
import { ContentType, Sex } from "../services/api";

export function logShowContentDetail(content: Content) {
  Analytics.logEvent("show_content_detail", {
    castId: content.station.cast.id, // あんまり当てにならない
    castName: content.station.cast.name,
    contentType: content.type,
  });
}

export function logShowUserRegistration(content: Content) {
  Analytics.logEvent("show_user_registration", {
    castId: content.station.cast.id, // あんまり当てにならない
    castName: content.station.cast.name,
    contentType: content.type,
  });
}

export function logInitial(targetSex: Sex) {
  Analytics.setUserProperties({
    targetSex: targetSex,
  });
}

export function logStart() {
  Analytics.logEvent("start");
}

export function logSetCurrentType(type: ContentType) {
  Analytics.logEvent("set_current_type", {
    type: type,
  });
}

export function logShowRequestScreen(from: string) {
  Analytics.logEvent("show_request_screen", {
    from: from,
  });
}

export function logCurrentScreen(screen: string) {
  Analytics.setCurrentScreen(screen);
}
