import { useCallback, useEffect, useState } from "react";

// interface AudioAndUrl {
//   url: string;
//   audio: HTMLAudioElement | null;
// }
//
// const sampleVoiceState = selector({
//   key: `sampleVoiceState`,
//   get: () => {
//     return { url: "", audio: null } as AudioAndUrl;
//   },
// });

export const useAudio = (url: string): [boolean, () => any, () => void] => {
  // TODO recoil で状態を共有。ただし、 re render は最小にしたい
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  // 強制的に re render するために
  const [, setForceUpdate] = useState(false);

  const forceUpdate = useCallback(
    () => setForceUpdate((prevState) => !prevState),
    []
  );

  useEffect(() => {
    if (audio) {
      console.log("audio: setup");
      audio.addEventListener("play", forceUpdate);
      audio.addEventListener("pause", forceUpdate);
      audio.addEventListener("ended", forceUpdate);

      return () => {
        console.log("audio: cleanup");
        audio.removeEventListener("play", forceUpdate);
        audio.removeEventListener("pause", forceUpdate);
        audio.removeEventListener("ended", forceUpdate);
      };
    }
  }, [audio]);

  const play = useCallback(() => {
    if (audio) {
      if (audio.paused) {
        audio.play();
      }
    } else {
      const audio2 = new Audio(url);
      setAudio(audio2);
      audio2.play();
    }
  }, [audio]);
  const pause = useCallback(() => {
    if (audio) {
      audio.pause();
    }
  }, [audio]);

  return [!audio || audio.paused, play, pause];
};
