import * as React from "react";
import { FC, Suspense, useCallback } from "react";
import { StationList } from "./StationList";
import styled from "styled-components";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import { WebButton } from "../../components/WebButton";
import { gray5, primaryColor } from "../../constants/Colors";
import { Menu } from "./Menu";
import { useRecoilState } from "recoil";
import { currentTypeState } from "./data";
import { Footer } from "../../components/Footer";
import { ContentType, Sex } from "../../services/api";
import { Content } from "../../types";
import { logSetCurrentType, logStart } from "../../utils/analytics";
import { Header } from "../../components/Header";

const heroMale = require("../../assets/images/naisyo.jpg");

type TemplateProps = {
  onSelectContent?: (content: Content) => void;
  sex: Sex;
};

/**
 * このページは複雑なので現在 web 特化。
 * 故にロジックはあまり持たないように。
 *
 */
export const Template: FC<TemplateProps> = ({ sex, onSelectContent }) => {
  const [scrollRef1, scroll1] = useScrollIntoView();
  const [scrollRef2, scroll2] = useScrollIntoView();
  const [currentType, setCurrentType] = useRecoilState(currentTypeState);

  const onMenuChange = useCallback((type: ContentType) => {
    setCurrentType(type);
    logSetCurrentType(type);
    scroll2();
  }, []);

  const onStart = useCallback(() => {
    logStart();
    scroll1();
  }, []);

  return (
    <Container>
      <HeaderWrap>
        <Header />
      </HeaderWrap>
      <Page1>
        <Page1Top>
          <BgImg src={heroMale} />
          <BgImgOverlay />

          <CatchphraseBox>
            <Catchphrase>あなただけの</Catchphrase>
            <Catchphrase>ラジオを聴こう</Catchphrase>
            <SubCatchphrase>あなた専用のリアルタイムラジオ</SubCatchphrase>
          </CatchphraseBox>
        </Page1Top>

        <StartButtonWrap>
          <StartButton onClick={onStart}>聴いてみる</StartButton>
        </StartButtonWrap>
      </Page1>

      <DescriptionWrap ref={scrollRef1}>
        <DescriptionBox>
          <Description>
            Your Radio はあなたがリクエストした内容を、
            パーソナリティーがあなたのためだけに放送するラジオサービスです。
          </Description>

          <Description>
            あなた専用のラジオなので、聴きたいときに、聴きたい内容を、あなただけが視聴できます。
          </Description>

          <Description>いま どんなラジオが聴きたいですか？</Description>
        </DescriptionBox>
      </DescriptionWrap>

      <MenuWrap ref={scrollRef2}>
        <Menu currentType={currentType} onChange={onMenuChange} />
      </MenuWrap>
      <Suspense fallback={<div>...</div>}>
        <StationList sex={sex} onSelectContent={onSelectContent} />
      </Suspense>
      <Footer sex={sex} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const HeaderWrap = styled.div`
  //position: sticky;
  //top: 0;
`;

const CatchphraseBox = styled.div`
  // スマフォのナビゲーションバーのため、すこしずらす
  padding-bottom: 128px;
`;

const Catchphrase = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    YuGothic, "Yu Gothic Medium", "Hiragino Sans", "Hiragino Kaku Gothic ProN",
    Meiryo, sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
`;

const SubCatchphrase = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    YuGothic, "Yu Gothic Medium", "Hiragino Sans", "Hiragino Kaku Gothic ProN",
    Meiryo, sans-serif;
  font-size: 18px;
  color: #fff;
  margin-top: 12px;
`;

const Page1 = styled.div`
  // - 50px はざっくりヘッダー分
  height: calc(100vh - 50px);
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Page1Top = styled.div`
  width: 100%;
  height: 100%;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DescriptionWrap = styled.div``;

const DescriptionBox = styled.div`
  padding: 64px 16px 64px;

  text-align: center;
`;

const Description = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    YuGothic, "Yu Gothic Medium", "Hiragino Sans", "Hiragino Kaku Gothic ProN",
    Meiryo, sans-serif;

  font-size: 16px;
  padding: 8px;
  font-weight: bold;
  color: ${gray5};
`;

const BgImg = styled.img`
  position: absolute;

  z-index: -1;
  filter: blur(1px) brightness(0.4);
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  //object-position: -50px 0;
  //object-position: -346px;
`;

const BgImgOverlay = styled.div`
  position: absolute;

  z-index: -1;
  background-color: ${primaryColor};
  opacity: 0.2;
  width: 100%;
  height: 100%;
`;

const StartButtonWrap = styled.div`
  position: absolute;
  z-index: 1;
  margin: auto;
  bottom: 150px;
`;

const StartButton = styled(WebButton)`
  background: linear-gradient(to right, ${primaryColor} 20%, ${primaryColor});
  color: #fff;
  font-size: 21px;
  font-weight: bold;
  padding: 12px 40px;
  border-radius: 36px;
`;

const MenuWrap = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;
