import { MutableRefObject, useCallback, useRef } from "react";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

/**
 * polyfill だと位置指定が効かないので注意
 */
export default function useScrollIntoView(): [
  MutableRefObject<any>,
  (options?: ScrollIntoViewOptions) => void
] {
  const ref = useRef<HTMLElement>(null);

  const scroll = useCallback((options?: ScrollIntoViewOptions) => {
    if (ref.current) {
      ref.current.scrollIntoView({ ...options, behavior: "smooth" });
    }
  }, []);
  return [ref, scroll];
}
