import * as React from "react";
import { FC } from "react";
import { Image } from "react-native";
import { StyleSheet, TouchableOpacity } from "react-native";

const logo = require("../assets/images/logo_transparent_background.png");

export const Logo: FC = () => {
  return <Image source={logo} style={styles.logo} />;
};

const aspect = 1142 / 3400;
const styles = StyleSheet.create({
  logo: {
    width: 150,
    height: 150 * aspect,
  },
});
