import * as React from "react";
import { FC } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text, View } from "../../components/Themed";
import { Sing } from "../../services/api";
import { Icon } from "react-native-elements";
import { primaryColor2 } from "../../constants/Colors";

export type StationViewProps = {
  sing: Sing;
  onPress: () => void;
};

export const SingView: FC<StationViewProps> = ({ sing, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View key={sing.id} style={styles.read}>
        <Icon name="music-note" type="material" size={15} />
        <Text>{sing.description}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  read: {
    borderRadius: 12,
    paddingHorizontal: 8,
    paddingVertical: 8,
    margin: 4,
    backgroundColor: primaryColor2,
    overflow: "hidden",

    flexDirection: "row",
    alignItems: "center",
  },
});
