import * as React from "react";
import { FC } from "react";
import { View } from "./Themed";
import { Link } from "@react-navigation/native";
import { StyleSheet, Text } from "react-native";
import { primaryColor } from "../constants/Colors";
import { requestScreen } from "../navigation/LinkingConfiguration";
import { Sex } from "../services/api";

export type FooterProps = {
  sex: Sex;
};

export const Footer: FC<FooterProps> = ({ sex }) => {
  return (
    <View style={styles.container}>
      <View style={styles.links}>
        <Link
          to={requestScreen({ from: "company", sex: sex })}
          style={styles.link}
        >
          会社情報
        </Link>
        <Link
          to={requestScreen({ from: "terms", sex: sex })}
          style={styles.link}
        >
          利用規約
        </Link>
        <Link
          to={requestScreen({ from: "privacy-policy", sex: sex })}
          style={styles.link}
        >
          プライバシーポリシー
        </Link>
        <Link
          to={requestScreen({ from: "transactions", sex: sex })}
          style={styles.link}
        >
          特定商取引法に基づく表示
        </Link>
      </View>
      <Text style={styles.copyright}>
        ©Your Radio, Inc. All rights reserved.
      </Text>
      <Text style={styles.copyright}>
        <Link to="https://jp.freepik.com/photos/blue">
          Cookie_studio - jp.freepik.com によって作成された blue 写真
        </Link>
      </Text>
      {/*<Text style={styles.copyright}>*/}
      {/*  <Link to="https://jp.freepik.com/photos/kids">*/}
      {/*    Freepik - jp.freepik.com によって作成された kids 写真*/}
      {/*  </Link>*/}
      {/*</Text>*/}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: primaryColor,
  },
  links: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    backgroundColor: "transparent",
  },
  link: {
    fontSize: 12,
    color: "#fff",
    marginRight: 4,
  },
  copyright: {
    marginTop: 12,
    fontSize: 10,
    color: "#fff",
  },
});
