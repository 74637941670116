import * as React from "react";
import { FC } from "react";
import { StyleSheet, TouchableHighlight, View } from "react-native";
import { Icon } from "react-native-elements";

type Props = {
  text: string;
  iconName: string;
  iconType: string;
  backgroundColor: string;
  active: boolean;
  onPress?: () => void;
};

export const MenuItem: FC<Props> = ({
  text,
  iconName,
  iconType,
  backgroundColor,
  active,
  onPress,
}) => {
  const color = "#fff";
  const icon = <Icon size={32} name={iconName} type={iconType} color={color} />;

  return (
    <TouchableHighlight
      style={[
        styles.menuItem,
        {
          backgroundColor: backgroundColor,
        },
      ]}
      onPress={onPress}
    >
      <>
        {!active && <View style={styles.dark} />}
        {icon}
        {text}
      </>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  menuItem: {
    fontFamily: "space-mono",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    flexGrow: 1,

    fontWeight: "bold",
    color: "#fff",

    paddingHorizontal: 8,
    paddingVertical: 8,

    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowOpacity: 0.4,
    shadowRadius: 4,
    overflow: "hidden",
  },
  dark: {
    position: "absolute",
    width: "100%",
    height: "100%",

    left: 0,
    right: 0,

    zIndex: 1,

    opacity: 0.5,
    backgroundColor: "#000",
  },
});
