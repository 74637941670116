import * as React from "react";
import { FC } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text, View } from "../../components/Themed";
import { Talk } from "../../services/api";
import { primaryColor2 } from "../../constants/Colors";

export type StationViewProps = {
  talk: Talk;
  onPress?: () => void;
};

export const TalkView: FC<StationViewProps> = ({ talk, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View key={talk.id} style={styles.read}>
        <Text>
          {talk.description} ({talk.minutes} 分)
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  read: {
    borderRadius: 12,
    paddingHorizontal: 8,
    paddingVertical: 8,
    margin: 4,
    backgroundColor: primaryColor2,
    overflow: "hidden",
  },
});
