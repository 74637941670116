import * as React from "react";
import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { MenuItem } from "./MenuItem";
import {
  primaryColor,
  readColor,
  singColor,
  talkColor,
} from "../../constants/Colors";
import { ContentType } from "../../services/api";

export type MenuProps = {
  currentType: ContentType;
  onChange?: (type: ContentType) => void;
};

export const Menu: FC<MenuProps> = ({ currentType, onChange }) => {
  return (
    <View style={styles.menu}>
      <MenuItem
        text="語ってほしい"
        iconName="message-circle"
        iconType="feather"
        backgroundColor={talkColor}
        active={currentType === "talk"}
        onPress={() => onChange && onChange("talk")}
      />
      <MenuItem
        text="読んでほしい"
        iconName="open-book"
        iconType="entypo"
        backgroundColor={readColor}
        active={currentType === "read"}
        onPress={() => onChange && onChange("read")}
      />
      <MenuItem
        text="歌ってほしい"
        iconName="modern-mic"
        iconType="entypo"
        backgroundColor={singColor}
        active={currentType === "sing"}
        onPress={() => onChange && onChange("sing")}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  menu: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
});
