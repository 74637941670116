// https://coolors.co/d8dcff-37419e-c38d94-ea4c89-31afb4

const tintColorLight = "#2f95dc";
const tintColorDark = "#fff";

export const primaryColor = "#90BBBF";

export const talkColor = "#90BBBF";
export const readColor = "#ABD4CB";
export const singColor = "#77BBC7";

export const primaryLightBgColor = "#DBE9EA";
export const primaryColor2 = "#B2D7D7";

export const gray5 = "#555";

export default {
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
  },
};
