import * as React from "react";
import { FC, useCallback, useEffect, useState } from "react";
import { Content, RootStackParamList } from "../../types";
import { StackScreenProps } from "@react-navigation/stack";
import { Template } from "./Template";
import { Platform, View } from "react-native";
import { Overlay } from "react-native-elements";
import Modal from "react-native-web-modal";
import { ContentDetail } from "../../components/ContentDetail/ContentDetail";
import { logCurrentScreen, logInitial } from "../../utils/analytics";
import { useSetRecoilState } from "recoil";
import { sexState } from "../../data";

type Props = StackScreenProps<RootStackParamList, "Root">;

export const RootScreen: FC<Props> = ({ route }) => {
  const sex = route.params.sex === "male" ? "male" : "female";
  const setSex = useSetRecoilState(sexState);

  useEffect(() => {
    logInitial(sex);
    logCurrentScreen("root");
    setSex(sex);
  }, []);

  const [selectedContent, setSelectedContent] = useState<Content | null>(null);

  const selectContent = useCallback((content: Content) => {
    setSelectedContent(content);
  }, []);

  const unselectContent = useCallback(() => {
    setSelectedContent(null);
  }, []);

  return (
    <View>
      <Template sex={sex} onSelectContent={selectContent} />
      {selectedContent && (
        <Overlay
          isVisible
          onBackdropPress={unselectContent}
          ModalComponent={Platform.OS === "web" && Modal}
        >
          <ContentDetail content={selectedContent} />
        </Overlay>
      )}
    </View>
  );
};
