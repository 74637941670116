import * as React from "react";
import { FC } from "react";
import { StyleSheet, Text } from "react-native";
import { View } from "../Themed";
import { Sing, Talk } from "../../services/api";

type SingViewProps = {
  sing: Sing;
};

export const SingView: FC<SingViewProps> = ({ sing }) => {
  return (
    <View>
      <View>
        <Text style={styles.talkName}>{sing.description}</Text>
      </View>

      <View>
        <Text>金額: {sing.price} 円</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  talkName: {
    fontSize: 20,
    fontWeight: "bold",
    marginVertical: 8,
  },
});
