import { Cast, ContentId, Read, ReadingPrice, Station } from "../services/api";

const baseUrl = "http://d3fzg4gd0tu5r2.cloudfront.net/avatar/female/";

const maleBaseUrl = "https://dpe3xp1a7mryo.cloudfront.net/avatars/male/";

const maleSampleVoiceBaseUrl =
  "https://dpe3xp1a7mryo.cloudfront.net/sample-voices/male/";

let nextId = 1;

function makeId(): ContentId {
  return String(nextId++);
}

export const maleCasts: Cast[] = [
  {
    id: "m1",
    avatarUrl: maleBaseUrl + "30.20.png",
    name: "一ノ瀬【⁺rin】",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m2",
    avatarUrl: maleBaseUrl + "45.26.png",
    name: "ゆーちゃんまん",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m3",
    avatarUrl: maleBaseUrl + "44.52.png",
    name: "紅炎",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m4",
    avatarUrl: maleBaseUrl + "48.23.png",
    name: "⨋ 咲 城.【鴉】",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m5",
    avatarUrl: maleBaseUrl + "48.17.png",
    name: "♤カズ♠",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m6",
    avatarUrl: maleBaseUrl + "44.27.png",
    name: "深いスネーク【*＊】",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m7",
    avatarUrl: maleBaseUrl + "03.45.png",
    name: "アヤタ",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m8",
    avatarUrl: maleBaseUrl + "04.10.png",
    name: "❛艶❜音𝕍𝕠𝕚𝕔𝕖 グリス",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m9",
    avatarUrl: maleBaseUrl + "44.36.png",
    name: "黒猫",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m10",
    avatarUrl: maleBaseUrl + "46.00.png",
    name: "暇つぶしちゃん",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m11",
    avatarUrl: maleBaseUrl + "52.21.png",
    name: "たこさんウィンナー",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m12",
    avatarUrl: maleBaseUrl + "03.53.png",
    name: "みとり(✋˘ ˘👌)",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m13",
    avatarUrl: maleBaseUrl + "43.40.png",
    name: "らいらい🦁",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m14",
    avatarUrl: maleBaseUrl + "50.40.png",
    name: "錘玻。",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m15",
    avatarUrl: maleBaseUrl + "03.59.png",
    name: "櫻井雅士",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m16",
    avatarUrl: maleBaseUrl + "03.39.png",
    name: "そこのあんた",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m17",
    avatarUrl: maleBaseUrl + "45.12.png",
    name: "しりきう",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m18",
    avatarUrl: maleBaseUrl + "09.22.png",
    name: "きりと",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m19",
    avatarUrl: maleBaseUrl + "48.31.png",
    name: "🍩🍬シュガー",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
  {
    id: "m20",
    avatarUrl: maleBaseUrl + "10.12.png",
    name: "りぬ",
    sex: "male",
    sampleVoiceUrl: maleSampleVoiceBaseUrl + "",
  },
];

const femaleSampleVoiceBaseUrl = "";
export const femaleCasts: Cast[] = [
  {
    id: "f1",
    avatarUrl: baseUrl + "5.38.12.png",
    name: "めぐ",
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
  {
    id: "f2",
    avatarUrl: baseUrl + "aj_icon004.jpg",
    name: "蒼髪微少女みるみる",
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
  {
    id: "f4",
    avatarUrl: baseUrl + "5.14.48.png",
    name: "さくさく",
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
  {
    id: "f5",
    avatarUrl: baseUrl + "NCG196-scaled.jpg",
    name: '鈴"𝓡ﾟ',
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
  {
    id: "f6",
    avatarUrl: baseUrl + "5.44.00.png",
    name: "💩buririn🎮🍃🌿🌗 𓆈",
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
  {
    id: "f3",
    avatarUrl: baseUrl + "5.38.34.png",
    name: "クロミ",
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
  {
    id: "f7",
    avatarUrl: baseUrl + "5.15.32.png",
    name: "ねむいねむいいか",
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
  {
    id: "f8",
    avatarUrl: baseUrl + "5.15.46.png",
    name: "か ず は 。",
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
  {
    id: "f9",
    avatarUrl: baseUrl + "5.44.1.png",
    name: "魅惑のりんちゃん♡",
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
  {
    id: "f10",
    avatarUrl: baseUrl + "5.16.01.png",
    name: "るるう",
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
  {
    id: "f11",
    avatarUrl: baseUrl + "5.16.13.png",
    name: "-あらん-",
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
  {
    id: "f12",
    avatarUrl: baseUrl + "5.16.25.png",
    name: "保科",
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
  {
    id: "f13",
    avatarUrl: baseUrl + "5.15.17.png",
    name: "❊.L",
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
  {
    id: "f14",
    avatarUrl: baseUrl + "ECUfR2AU8AALQmf.jpg",
    name: "黒湯(ｸﾕ)",
    sex: "female",
    sampleVoiceUrl: femaleSampleVoiceBaseUrl + "",
  },
];

const simpleReadingPrices: ReadingPrice[] = [
  {
    minutes: 5,
    price: 100,
  },
  {
    minutes: 10,
    price: 200,
  },
  {
    minutes: 15,
    price: 300,
  },
  {
    minutes: 30,
    price: 500,
  },
  {
    minutes: 60,
    price: 1000,
  },
];

const simpleRead: Read = {
  languages: ["日本語"],
  prices: simpleReadingPrices,
};

const baseStations: Station[] = [
  {
    cast: femaleCasts[0],
    read: {
      languages: ["日本語", "English"],
      prices: simpleReadingPrices,
    },
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "聞いてほしいことがあるんだ",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "御飯作りながら雑談するよ",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 30,
        description: "寝落ちしたいの？",
        price: 200,
      },
    ],
    sings: [
      {
        id: makeId(),
        description: "アニソン",
        price: 100,
      },
      {
        id: makeId(),
        description: "最近の曲",
        price: 100,
      },
    ],
  },
  {
    cast: femaleCasts[1],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "とりとめもなく話すよ",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "悩み聞いてほしいんだよね",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "好きな人ができました",
        price: 500,
      },
    ],
    sings: [],
  },
  {
    cast: femaleCasts[2],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 30,
        description: "首都高について語る",
        price: 500,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "今ゲームしてるよ",
        price: 200,
      },
    ],
    sings: [],
  },
  {
    cast: femaleCasts[3],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "はじめまして",
        price: 200,
      },
    ],
    sings: [],
  },
  {
    cast: femaleCasts[4],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "東京の美味しいご飯の話をするよ",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "とりとめもなく話すよ",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 30,
        description: "寝るまでどうでもいい話するよ",
        price: 500,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "今日学校であったことを話すよ",
        price: 500,
      },
    ],
    sings: [],
  },
  {
    cast: femaleCasts[5],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 30,
        description: "悩み聞いてほしいんだ",
        price: 500,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "とりとめもなく話すよ",
        price: 200,
      },
    ],
    sings: [],
  },
  {
    cast: femaleCasts[6],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 60,
        description: "1時間位話を聞いてょ",
        price: 1000,
      },
    ],
    sings: [],
  },
  {
    cast: femaleCasts[7],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 30,
        description: "ねれないんだよね",
        price: 500,
      },
    ],
    sings: [],
  },
  {
    cast: femaleCasts[8],
    read: simpleRead,
    talks: [],
    sings: [],
  },
  {
    cast: femaleCasts[9],
    read: simpleRead,
    talks: [],
    sings: [
      {
        id: makeId(),
        description: "いまどきアニソン",
        price: 100,
      },
    ],
  },
  {
    cast: femaleCasts[10],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "サバの話ききたい？",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "さかな詳しいょ",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "とりとめもなく話すよ",
        price: 200,
      },
    ],
    sings: [],
  },
  {
    cast: femaleCasts[11],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "とりとめもなく話すよ",
        price: 200,
      },
    ],
    sings: [
      {
        id: makeId(),
        description: "適当に歌うよ",
        price: 100,
      },
    ],
  },
  // ここから
  {
    cast: maleCasts[12],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "ふわっと雑談",
        price: 200,
      },
    ],
    sings: [
      {
        id: makeId(),
        description: "眠れないの? 子守唄歌うよ",
        price: 100,
      },
    ],
  },
  {
    cast: maleCasts[13],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "目が覚める話をしようか",
        price: 200,
      },
    ],
    sings: [],
  },
  {
    cast: maleCasts[14],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "昨日あった話とかいろいろ",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "法律についての豆知識",
        price: 200,
      },
    ],
    sings: [],
  },
  {
    cast: maleCasts[15],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "好きだよ。ただひたすら。",
        price: 200,
      },
    ],
    sings: [],
  },
  {
    cast: maleCasts[16],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "ふわっと雑談",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "仕事の話 (IT系)",
        price: 200,
      },
    ],
    sings: [],
  },
  {
    cast: maleCasts[17],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "ふわっと雑談",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "せっかくなので政治の話でも",
        price: 200,
      },
    ],
    sings: [],
  },
  {
    cast: maleCasts[18],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "ふわっと雑談",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "ゲームやる? ゲームの話をするよ",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "料理の話",
        price: 200,
      },
    ],
    sings: [],
  },
  {
    cast: maleCasts[19],
    read: simpleRead,
    talks: [
      {
        id: makeId(),
        minutes: 10,
        description: "ふわっと雑談",
        price: 200,
      },
      {
        id: makeId(),
        minutes: 10,
        description: "プログラミングについて",
        price: 200,
      },
    ],
    sings: [],
  },
];

const maleStations = maleCasts.map((a, i) => ({
  ...baseStations[i],
  id: makeId(),
  cast: a,
}));

const femaleStations = femaleCasts.map((a, i) => ({
  ...baseStations[i],
  id: makeId(),
  cast: a,
}));

export const fakeStations: Station[] = [...maleStations, ...femaleStations];
