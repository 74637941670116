import * as React from "react";
import { FC } from "react";
import {
  Pressable,
  StyleSheet,
  TouchableHighlight,
  TouchableOpacity,
} from "react-native";
import { Text, View } from "./Themed";
import { Cast } from "../services/api";
import { Icon } from "react-native-elements";
import { useAudio } from "../hooks/useAudio";

export type StationViewProps = {
  cast: Cast;
};

export const CastNameAndSampleVoice: FC<StationViewProps> = ({ cast }) => {
  const [paused, play] = useAudio(cast.sampleVoiceUrl);

  return (
    <View style={styles.castNameAndVoice}>
      <Text style={styles.castName}>{cast.name}</Text>
      <TouchableOpacity
        disabled={!paused}
        onPress={() => {
          play();
        }}
      >
        <Icon name="sound" type="antdesign" size={24} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  castNameAndVoice: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "transparent",
  },

  castName: {
    fontSize: 13,
    fontWeight: "bold",
    paddingRight: 4,
  },
});
