import * as React from "react";
import { FC, useCallback } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text, View } from "../../components/Themed";
import { ContentType, Station } from "../../services/api";
import { SingView } from "./SingView";
import { ReadView } from "./ReadView";
import { TalkView } from "./TalkView";
import { CastAvatar } from "../../components/CastAvatar";
import { CastNameAndSampleVoice } from "../../components/CastNameAndSampleVoice";
import { Content } from "../../types";
import { primaryLightBgColor } from "../../constants/Colors";

export type StationViewProps = {
  contentType: ContentType;
  station: Station;

  onSelectContent?: (content: Content) => void;
};

export const StationView: FC<StationViewProps> = ({
  contentType,
  station,
  onSelectContent,
}) => {
  const onSelectRead = useCallback(() => {
    onSelectContent &&
      onSelectContent({
        type: "read",
        station: station,
        read: station.read!,
      });
  }, []);

  return (
    <TouchableOpacity
      disabled={contentType !== "read"}
      style={[styles.container]}
      onPress={onSelectRead}
    >
      <CastNameAndSampleVoice cast={station.cast} />
      <View style={styles.bottom}>
        <View style={styles.left}>
          <CastAvatar cast={station.cast} />
          <Text style={styles.online}>いま聴ける</Text>

          {contentType === "read" && (
            <Text style={styles.maxMinutes}>
              最長{" "}
              {station.read?.prices[station.read?.prices.length - 1].minutes}{" "}
              分まで
            </Text>
          )}
        </View>
        <View style={styles.right}>
          {contentType === "talk" && (
            <View style={styles.contentList}>
              {station.talks.map((talk) => (
                <TalkView
                  key={talk.id}
                  talk={talk}
                  onPress={() =>
                    onSelectContent &&
                    onSelectContent({
                      type: "talk",
                      station: station,
                      talk: talk,
                    })
                  }
                />
              ))}
            </View>
          )}

          {contentType === "read" && (
            <View style={styles.contentList}>
              {station.read?.languages.map((lang) => (
                <ReadView key={lang} language={lang} />
              ))}
            </View>
          )}

          {contentType === "sing" && (
            <View style={styles.contentList}>
              {station.sings.map((sing) => (
                <SingView
                  key={sing.id}
                  sing={sing}
                  onPress={() =>
                    onSelectContent &&
                    onSelectContent({
                      type: "sing",
                      station: station,
                      sing: sing,
                    })
                  }
                />
              ))}
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "column",
    padding: 4,
    marginVertical: 8,
    paddingLeft: 8,
    backgroundColor: primaryLightBgColor,
  },
  bottom: {
    flexDirection: "row",
    backgroundColor: "transparent",
  },
  left: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: 8,
    backgroundColor: "transparent",
  },
  right: {
    flexDirection: "column",
    flexShrink: 1,
    backgroundColor: "transparent",
  },
  online: {
    color: "#2e8b57",
    fontWeight: "bold",
    paddingTop: 4,
    fontSize: 12,
  },
  maxMinutes: {
    fontSize: 12,
  },

  contentList: {
    marginTop: 4,
    flexDirection: "column",
    alignItems: "flex-start",
    flexWrap: "wrap",
    backgroundColor: primaryLightBgColor,
  },
});
