import * as React from "react";
import { FC } from "react";
import { StyleSheet, Text } from "react-native";

type DescriptionProps = {};

/**
 * 説明用のテキスト
 *
 * (少し小さめ)
 */
export const Description: FC<DescriptionProps> = ({ children }) => {
  return <Text style={styles.description}>{children}</Text>;
};

const styles = StyleSheet.create({
  description: {
    fontSize: 12,
  },
});
