import * as React from "react";
import { FC } from "react";
import { StyleSheet, Text } from "react-native";

type MainTextProps = {
  padding?: boolean;
};

/**
 * 通常のテキスト
 */
export const MainText: FC<MainTextProps> = ({ padding, children }) => {
  return (
    <Text style={[styles.main, { paddingVertical: padding ? 4 : 0 }]}>
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  main: {
    fontSize: 14,
  },
});
