import * as React from "react";
import { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Logo } from "./Logo";
import { Link } from "@react-navigation/native";
import { homeScreen } from "../navigation/LinkingConfiguration";
import { useRecoilValue } from "recoil";
import { sexState } from "../data";

export const Header: FC = () => {
  const sex = useRecoilValue(sexState);

  return (
    <View style={styles.header}>
      <Link to={homeScreen(sex)}>
        <Logo />
      </Link>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    justifyContent: "center",
    flexDirection: "row",
    backgroundColor: "#fff",
    width: "100%",
    height: 72,
    alignItems: "center",
    // ロゴ部分を加味して、ちょっとずらす
    paddingRight: 20,
  },
});
