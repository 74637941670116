import { atom, selector, selectorFamily } from "recoil";
import { ContentType, Sex, Station } from "../../services/api";
import { fakeStations } from "../../utils/fixtures";

const prefix = "RootScreen";

const stationsState = selector({
  key: `${prefix}-stations`,
  get: async () => {
    return new Promise<Station[]>((resolve) => {
      console.log("get");
      setTimeout(() => {
        resolve(fakeStations);
      }, 100);
    });
  },
});

export const currentTypeState = atom({
  key: `${prefix}-current-type`,
  default: "talk" as ContentType,
});

export const stationsFamily = selectorFamily({
  key: `${prefix}-stations2`,
  get: (p: { sex: Sex; type: ContentType }) => ({ get }) => {
    const base = get(stationsState).filter(
      (a) => a.cast.sex === undefined || a.cast.sex === p.sex
    );

    if (p.type === "talk") {
      return base.filter((a) => a.talks.length >= 1);
    }
    if (p.type === "read") {
      return base.filter((a) => a.read);
    }
    if (p.type === "sing") {
      return base.filter((a) => a.sings.length >= 1);
    }
    throw new Error("");
  },
});
