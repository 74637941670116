import * as React from "react";
import { FC } from "react";
import { StyleSheet, Text } from "react-native";
import { View } from "../../components/Themed";
import { useRecoilValue } from "recoil";
import { currentTypeState, stationsFamily } from "./data";
import { StationView } from "./StationView";
import { Sex } from "../../services/api";
import { Content } from "../../types";
import { primaryColor, primaryLightBgColor } from "../../constants/Colors";

type StationListProps = {
  onSelectContent?: (content: Content) => void;
  sex: Sex;
};

export const StationList: FC<StationListProps> = ({ sex, onSelectContent }) => {
  const currentType = useRecoilValue(currentTypeState);
  const stations = useRecoilValue(
    stationsFamily({ sex: sex, type: currentType })
  );

  return (
    <View style={styles.container}>
      <Text style={styles.question}>
        {currentType === "talk" && "聴きたい話を選んでね"}
        {currentType === "read" && "読んでほしいパーソナリティを選択してね"}
        {currentType === "sing" && "歌ってほしい内容を選んでね"}
      </Text>

      {stations.map((s) => (
        <StationView
          contentType={currentType}
          station={s}
          key={s.cast.id}
          onSelectContent={onSelectContent}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: primaryLightBgColor,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  question: {
    fontSize: 20,
    fontWeight: "bold",
    color: primaryColor,
    marginVertical: 16,
  },
});
