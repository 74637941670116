import * as React from "react";
import { FC } from "react";
import { StyleSheet } from "react-native";
import { View } from "./Themed";
import { Cast } from "../services/api";
import { Image } from "react-native-elements";

export type StationViewProps = {
  cast: Cast;
};

export const CastAvatar: FC<StationViewProps> = ({ cast }) => {
  return (
    <View>
      <Image style={styles.avatar} source={{ uri: cast.avatarUrl }} />
    </View>
  );
};

const styles = StyleSheet.create({
  avatar: {
    width: 80,
    height: 80,
    borderWidth: 1,
    borderColor: "#999",
  },
});
