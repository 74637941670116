import * as Linking from "expo-linking";
import { LinkingOptions } from "@react-navigation/native";
import { Sex } from "../services/api";

/**
 * URL => Screen name を紐付ける
 *
 * https://reactnavigation.org/docs/configuring-links/
 */
export default {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Request: "request",
      Root: "",
      NotFound: "*",
    },
  },
} as LinkingOptions;

export function requestScreen(p: { from: string; sex: Sex }) {
  return `/request?from=${p.from}&sex=${p.sex}`;
}

export function homeScreen(sex: Sex) {
  return `/?sex=${sex}`;
}
